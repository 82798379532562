import { useEffect } from 'react';

import { useAppContext, useClient } from '@bamboard/app-context';
import { DashboardLabel, useDashboardUrlQuery } from '@bamboard/schema';

export function useQuicksightUrl(input: DashboardLabel) {
  const queryClient = useClient();
  const [{ region }] = useAppContext();

  const { data, isLoading, isError, refetch } = useDashboardUrlQuery(
    queryClient,
    {
      input,
    },
    {
      retry: 3,
      retryDelay: (c) => Math.min(Math.pow(c, 1.2) * 1000, 3),
      staleTime: 60000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch().catch((error) => {
      console.log('useQuicksightUrl.refetch() Error: ', error);
    });
  }, [region]);

  return { url: data?.dashboardUrl, isLoading, isError };
}
